import { Zalacznik } from '../../wspolne/zalacznik';
import { RodzajPolaSpisu } from './rodzajPolaSpisu';
import { WidocznoscPolaNaOswiadczeniu } from './widocznoscPolaNaOswiadczeniu';
import {
  WymagalnoscPolaSpisu,
  WymagalnoscPolaSpisuOperator,
} from './wymagalnoscPolaSpisu';

export class DodajPoleSpisuModel {
  projektId: string;
  rodzajPolaSpisu?: RodzajPolaSpisu;
  slownikId: string;
  kolumnaSlownika: string;
  wyrazenieRegularne: string;
  maksymalnaLiczbaZnakow?: number;
  tylkoDoOdczytu: boolean;
  // wymagalne: boolean;
  skanowanieZablokowane: boolean;
  czyWartoscUnikalna: boolean;
  czyUkrycWKolektorze: boolean;
  czyUzupelnicAutomatycznieZEwidencji: boolean;
  czyDopisacZeraNaPoczatku: boolean;
  czyDopisacZeraNaKoncu: boolean;
  czySzukacWEwidencji: boolean;
  liczbaZerDoUzupelnienia?: number;
  wartoscDomyslna: string;
  nazwaPola: string;
  czyDomyslnieWRaporcie: boolean;
  regexKomunikat: string;
  rodzajSzukaniaWParowaniuRecznym: string;
  czyParowanieReczne: boolean;
  czyPoleMozeBycKopiowane: boolean;
  wymagalnosc: WymagalnoscPolaSpisu;
  wymagalnoscPolaSpisuId: Array<string>;
  wymagalnoscSlownikId: string;
  wymagalnoscPolaSpisuOperator: WymagalnoscPolaSpisuOperator;
  widocznoscPolaNaOswiadczeniu: WidocznoscPolaNaOswiadczeniu;
  czyMaskaWlaczonaNaOswiadczeniu: boolean;
  separatorKoduQr: Array<string>;
  przypiszZKoduQr: boolean;
  kopiujZPoprzedniegoSrodka: boolean;

  usuwacZnakiPoSkanowaniu: boolean;
  usuwacZPoczatkuPoSkanowaniu: boolean;
  usuwacZKoncaPoSkanowaniu: boolean;
  znakiDoUsunieciaPoSkanowaniu: string;
  liczbaZnakowDoUsunieciaZPoczatkuPoSkanowaniu?: number;
  liczbaZnakowDoUsunieciaZKoncaPoSkanowaniu?: number;
  wymagalnoscWartoscWInnymPolu: string;

  informacjaOPolu: string;
  zdjecieDoInformacjiOPolu?: Zalacznik;

  odczytajZeZdjecia: boolean;
  zostanWPoluPoZeskanowaniu:boolean;
}
